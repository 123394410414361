import React, { memo, useState } from 'react'
import intl from 'react-intl-universal'
import { produce } from 'immer'
import classNames from 'classnames'
import Tippy from '@tippyjs/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import { useMutation } from '@apollo/client'
import { Transition } from '@headlessui/react'
import { UserStudy } from 'graphql/schemas/user/UserStudy'
import { Action, upsellAction } from 'graphql/schemas/action/Action'
import { Feature, User } from 'graphql/schemas/user/User'
import { deleteActionMutation } from 'graphql/mutations/action/deleteAction'
import { getAppStateQuery } from 'graphql/queries/app/getAppState'
import { getCurrentSubscriptionQuery } from 'graphql/queries/user/getUser'
import { getChaptersForCourseQuery } from 'graphql/queries/chapter/getChaptersForCourse'
import { isActionCompleted } from 'models/action_models'
import { showAlert } from 'components/utils/Alert'
import { showModal } from 'components/utils/CustomModal'
import CommentsCounter from 'components/group/CommentsCounter'
import LikesCounter from 'components/group/LikesCounter'
import { getSignedValue } from 'actions/files'
import { augmentExplain, augmentGreeting, augmentName } from 'utils/utils'
import { createMarkup, copyToClipboard } from 'utils/functions'
import { localeFormat } from 'utils/dateFunctions'

type ActionItemBasic = Pick<Action, 'id'|'courseId'|'chapterId'|'contentType'|'contentUrl'|'audioUrl'|'userAction'|'interactionTime'|'title'|'rewards'|'description'|'placeholderAnswer'|'invisible'>

type ActionItemProps = {
  companyId: string;
  first: boolean;
  help: boolean;
  engagement?: number;
  graduationGrade: number;
  enabled?: boolean;
  status?: string;
  isEditing: boolean;
  action: ActionItemBasic;
  user: Pick<User, 'id'|'profile'|'features'>;
  hideAssessmentsAfterCompleted: boolean;
  study: UserStudy|null;

  onClick?: (action: any) => void;
  divDirection?: React.CSSProperties | {} | undefined;
}

export const ActionItem = memo((props: ActionItemProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [showVideo, setShowVideo] = useState(false)

  const handleClick = (e?, type?) => {
    if (type === 'keyboard' && e.code !== 'Enter') return

    if (e.target.closest('.edit-action') || e.target.classList.contains('menu-item')) return

    if (props.action.contentType === 'assessment' && props.hideAssessmentsAfterCompleted && !props.isEditing) {
      return showModal({
        title: intl.get('error_dialog_title'),
        content: intl.get('assessment_action_disabled_message'),
        secondaryButton: false,
        primaryText: intl.get('ok'),
      })
    }

    if (props.enabled || props.isEditing) {
      if (!props.isEditing && ((['audio', 'video', 'link', 'external'].some(t => t === props.action.contentType) && !props.action.contentUrl)
      || (props.action.contentType === 'image' && !props.action.contentUrl && !props.action.audioUrl))) {
        showModal({
          title: intl.get('action_no_content_title'),
          content: intl.get('action_no_content_text'),
          secondaryButton: false,
          primaryText: intl.get('ok'),
        })
      }
      else {
        props.onClick && props.onClick(props.action)
      }
    }
  }

  const gotoSharedItem = () => {
    const action = props.action
    const str = `${props.companyId}-${action.courseId}-${action.chapterId}-${action.id}-${props.user.id}`
    navigate(`/learn/share/${str}`)
  }

  const { enabled, action, study, isEditing, engagement, graduationGrade, companyId, status, hideAssessmentsAfterCompleted, divDirection } = props
  const { contentType, interactionTime } = props.action

  let isDisabled = !enabled
  const isCompleted = !!(!isEditing && isActionCompleted(action.contentType, action.placeholderAnswer || '', study))
  const hasInAppReward = action.rewards.some(r => r.channel === 'inapp')
  const hasEmailReward = action.rewards.some(r => r.channel === 'email')
  const hasRatingReward = action.rewards.some(r => r.channel === 'rating')

  const actionMenu = <ActionEditorMenu action={action} features={props.user.features} />

  if (!enabled && (contentType === 'certificate' && engagement && engagement < graduationGrade)) {
    isDisabled = true
  }

  if (action.contentType === 'quote') {
    return (
      <Quote
        menu={actionMenu}
        id={action.id}
        title={action.title}
        description={action.description || ''}
        user={props.user}
        isDisabled={isDisabled}
        isEditing={isEditing}
        rewards={{
          hasInAppReward,
          hasEmailReward,
          hasRatingReward,
        }}
        handleClick={handleClick}
      />
    )
  }

  const shareKey = { companyId: props.companyId, courseId: action.courseId, chapterId: action.chapterId, actionId: action.id, userId: props.user.id }
  const signedImageUrl = action.contentType === 'photo' && study?.answer && getSignedValue(study.answer) || null
  const signedThumbnail = study?.thumbnail && getSignedValue(study.thumbnail) || null

  const [filename] = study?.answer?.split('/').slice(-1) || []
  const fn = filename && filename.replace(/(_[\d\w]{20})/, '') || 'unknown_file'
  const hiddenAssessments = (action.contentType === 'assessment' && hideAssessmentsAfterCompleted)

  const cn = classNames({
    'action-item-v2 rounded-md shadow-md cursor-pointer transition break-words': true,
    [action.contentType]: true,
    [`focus:ring-actions-${action.contentType}`]: true,
    completed: isCompleted,
    disabled: !isEditing && (!enabled || hiddenAssessments),
    'hover:shadow-lg': enabled,
    'ring-offset-2 ring': location.hash === `#${action.id}`,
  })

  return (
    <div id={`#${action.id}`} className={cn} onKeyDown={e => handleClick(e, 'keyboard')} onClick={handleClick} tabIndex={0}>
      <div className="flex mb-4 rounded-md" data-test="action-item">
        {isEditing && (
          <div className="flex items-center justify-center px-4 bg-white rounded-md space-x-4">
            <DragHandle />
          </div>
        )}

        <div className={`w-1/3 md:w-1/5 ${isEditing ? 'bg-white' : `bg-actions-${contentType}`} ${isEditing ? `text-actions-${contentType}` : 'text-white'} p-4 md:p-8 rounded-l-md flex items-center justify-start flex-col`}>
          <div className="font-noto font-medium text-md md:text-lg">
            <UserAction ua={action.userAction} />
          </div>

          <div className="required-time text-sm mt-2">
            <span>{interactionTime} {intl.get('min')}</span>
          </div>
        </div>

        <div className="w-2/3 md:w-4/5 action-content bg-white p-4 md:p-8 rounded-r-md">
          <div className="flex-1 items-center">
            <div className="font-bold md:text-lg">
              <Title
                isCertificate={action.contentType === 'certificate'}
                title={action.title}
                user={props.user}
                divDirection={divDirection}
              />
            </div>
          </div>

          {contentType === 'qa' && study?.answer && study.answer !== action.placeholderAnswer && !isEditing &&
            <div className={`action-description text-pretty mt-8 ${contentType}`} dangerouslySetInnerHTML={createMarkup(study.answer, true)}/>
          }

          {study && !isEditing && contentType === 'photo'
            ? signedThumbnail
              ? !showVideo
                ? <div className="relative mt-4" onClick={(e) => {
                  e.stopPropagation()
                  setShowVideo(true)
                }}>
                    <img src={signedThumbnail} className="w-full" />
                    <img src="/images/play-button.png" className="absolute" style={{ left: '50%', marginLeft: '-33px', top: '50%', marginTop: '-33px' }} />
                  </div>
                : <video src={String(signedImageUrl)} style={{ width: '100%', height: 300 }} controls className="mt-4">
                    {intl.get('video_not_supported')}
                  </video>
              : signedImageUrl && signedImageUrl.toString() !== ''
                ? <div className="action-photo mt-4">
                    <img src={String(signedImageUrl).replace('http:', 'https:')} className="w-full" />
                  </div>
                : null
            : null
          }

          <div className="flex-1 pt-4 -ml-2">
            {contentType === 'upload' && !!study?.answer && !isEditing && (
              <div className="ml-2 text-eggplant">{fn}</div>
            )}

            {!isEditing && study && !study.answer && study.reminder && study.reminder.at && study.reminder.at > Number(new Date()) && (
              <Tippy placement="left" content={intl.get('snoozed_until', { 0: localeFormat(study.reminder.at, 'p') })}>
                <i className="icon-alarm-snooze-light" />
              </Tippy>
            )}

            {!isEditing && status && (
              <span className="redTag relative red ml-2">{status}</span>
            )}

            {!isEditing && action.invisible && (
              <span className="redTag relative bg-gray-600 ml-2 uppercase">{intl.get('invisible_text')}</span>
            )}

            {isEditing && action.rewards.length > 0 && (
              <>
                {hasInAppReward && <span className="redTag relative bg-yellow-400 ml-2">{intl.get('chapter_edit_inapp')}</span>}
                {hasEmailReward && <span className="redTag relative bg-yellow-400 ml-2">{intl.get('chapter_edit_email')}</span>}
                {hasRatingReward && <span className="redTag relative bg-yellow-400 ml-2">{intl.get('chapter_edit_rating')}</span>}
              </>
            )}
          </div>

          {study?.sharedAt && !isEditing && action.contentType !== 'certificate'
            ? <div className="share-meta text-right">
                <div className="action-activity space-x-2">
                  <CommentsCounter
                    lastCommentAt={study.lastCommentAt || 0}
                    commentsLastSeenAt={study.commentsLastSeenAt || 0}
                    count={study.commentsCount}
                    onClick={gotoSharedItem}
                  />

                  <LikesCounter
                    shareKey={shareKey}
                    defaultCount={study.likesCount}
                    defaultLikedByMe={study.likedByMe}
                  />
                </div>

                <span className="icon-team-filled shared-icon" />
              </div>
            : null
          }
        </div>

        {!isEditing && (
          <div className="flex items-center bg-white px-5">
            {action.contentType !== 'certificate' && (
              <div className={`w-6 h-6 ${isCompleted ? `border bg-actions-${contentType} border-actions-${contentType}` : ''} rounded-full flex items-center justify-center`}>
                {isCompleted && <i className="icon icon-check-light text-white font-bold text-sm" />}
              </div>
            )}

            <svg className="ml-4" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.589966 10.59L5.16997 6L0.589966 1.41L1.99997 0L7.99997 6L1.99997 12L0.589966 10.59Z" fill="#4A4A4A"/>
            </svg>
          </div>
        )}

        {isEditing && (
          <div className="flex items-center bg-white px-5">
            {actionMenu}
          </div>
        )}
      </div>
    </div>
  )
})


const DragHandle = () => (
  <span className="cursor-grab text-lg">
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12Z" fill="#101010"/>
    </svg>
  </span>
)

const ActionEditorMenu = ({ action, features }: { action: ActionItemBasic, features: Feature[] }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [deleteAction] = useMutation(deleteActionMutation)

  const copyString = JSON.stringify({ fromCourseId: action.courseId, fromChapterId: action.chapterId, fromActionId: action.id })

  return (
    <div className="edit-action text-left">
      <Popover
        isOpen={isOpen}
        onClickOutside={() => setIsOpen(false)}
        positions={['right', 'bottom', 'top']}
        align="start"
        content={() => (
          <Transition
            show={isOpen}
            appear={true}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <div
              className="z-10 w-40 custom-dropdown">
              <div className="text-sm leading-6 px-4 pt-2 text-gray-400 tracking-wide">{intl.get('global_action')}</div>

              <div className="py-1">
                {!upsellAction(action.contentType, features) && (
                  <Tippy placement="top" content={intl.get('paste_action')}>
                    <button
                      onClick={() => {
                        setIsOpen(false)
                        localStorage.setItem('copiedAction', copyString)
                        showAlert('Action copied!')
                      }}
                      className="menu-item flex justify-between w-full px-4 py-1.5 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                      {intl.get('global_copy')}
                    </button>
                  </Tippy>
                )}

                <button
                  onClick={() => {
                    setIsOpen(false)
                    showModal({
                      title: intl.get('action_tooltip_remove'),
                      content: intl.get('global_are_you_sure_header'),
                      destructive: true,
                      primaryAction: () => {
                        deleteAction({
                          variables: {
                            courseId: action.courseId,
                            chapterId: action.chapterId,
                            actionId: action.id,
                          },
                          optimisticResponse: {
                            __typename: 'Mutation',
                            deleteAction: {
                              id: action.id,
                              courseId: action.courseId,
                              chapterId: action.chapterId,
                              __typename: 'ActionId',
                            },
                          },
                          update: (proxy, { data: { deleteAction } }) => {
                            const app: any = proxy.readQuery({ query: getAppStateQuery })
                            const user: any = proxy.readQuery({
                              query: getCurrentSubscriptionQuery,
                              variables: {
                                userId: app.appState.loggedInAs.uid,
                              },
                            })
                            const cc = user.user.profile.currentSubscription
                            const [companyId, courseId] = cc && cc.split('-') || ['', '']
                            const data: any = proxy.readQuery({
                              query: getChaptersForCourseQuery,
                              variables: {
                                companyId,
                                courseId: action.courseId,
                                returnAllAssessments: true,
                              },
                            })
                            if (!data) return

                            const chapter = data.chapters.find(c => c.id === deleteAction.chapterId)
                            if (!chapter) return

                            proxy.writeQuery({
                              query: getChaptersForCourseQuery,
                              variables: {
                                companyId,
                                courseId: action.courseId,
                                returnAllAssessments: true,
                              },
                              data: produce(data, (draft) => {
                                const index = draft.chapters.findIndex(c => c.id === deleteAction.chapterId)
                                if (index !== -1) draft.chapters[index].actions = draft.chapters[index].actions.filter(a => a.id !== deleteAction.id)
                              }),
                            })
                          },
                        })
                      },
                    })
                  }}
                  className="menu-item flex justify-between w-full px-4 py-1.5 text-sm text-coral hover:bg-gray-100">
                  {intl.get('global_delete')}
                </button>

                {(action.contentType === 'qa' || action.contentType !== 'quote') && (
                  <>
                    <div className="border-t border-gray-200 mt-2 mb-1 text-sm leading-6 px-4 pt-3 text-gray-400 tracking-wide">{intl.get('global_references')}</div>

                    {action.contentType === 'qa' && (
                      <button
                        onClick={() => {
                          setIsOpen(false)
                          copyToClipboard(`{{user:qa:${action.chapterId}:${action.id}}}`)
                        }}
                        className="menu-item flex justify-between w-full px-4 py-1.5 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                        {intl.get('copy_user_answer')}
                      </button>
                    )}

                    <button
                      onClick={() => {
                        setIsOpen(false)
                        copyToClipboard(`{{action:title:${action.chapterId}:${action.id}}}`)
                      }}
                      className="menu-item flex justify-between w-full px-4 py-1.5 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900">
                      {intl.get('copy_action_title')}
                    </button>
                  </>
                )}
              </div>
            </div>
          </Transition>
      )}>
        <button
          className="focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-yellow-400 rounded-full -mr-1"
          aria-label="Action options"
          onClick={() => setIsOpen(!isOpen)}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#101010">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
          </svg>
        </button>
      </Popover>
    </div>
  )
}

type QuoteProps = {
  menu: JSX.Element;
  id: string;
  title: string;
  description: string;
  user: Pick<User, 'id'|'profile'>;
  isEditing: boolean;
  isDisabled: boolean;
  rewards: {
    hasInAppReward: boolean;
    hasEmailReward: boolean;
    hasRatingReward: boolean;
  };
  handleClick: (e?, type?) => void;
}

const Quote = ({ id, menu, title, description, user, isEditing, rewards, isDisabled, handleClick }: QuoteProps) => {
  const cleanDescription = description && description.replace(/<(?:.|\n)*?>/gm, '').toUpperCase() || ''

  const actionClass = classNames({
    'action-item text-gray-700 cursor-pointer transition my-12': true,
    'hover:text-coral': !isDisabled,
    quote: true,
    disabled: isDisabled,
    noselect: isDisabled,
  })

  return (
    <div id={`#${id}`} className={actionClass} onClick={handleClick} onKeyDown={e => handleClick(e, 'keyboard')} tabIndex={0}>
      <div className="flex items-center">
        {isEditing && (
          <DragHandle />
        )}

        <div className="action-quote w-full" data-test="quote-item">
          {title === 'Optimistic update' && <div className="quote-text w-2/3 flex m-auto before:m-auto before:content-['\e924'] after:m-auto after:content-['\e924']">
            <div className="bg-gray-200 rounded animate-pulse w-[320px] h-[40px]"/>
          </div>}
          {title !== 'Optimistic update' && <p className="quote-text whitespace-pre-line text-3xl md:text-4xl">{augmentName(augmentGreeting(title), user)}</p>}
          
          {description && <p className="quote-action title">&mdash; {cleanDescription.length > 40 ? `${cleanDescription.substring(0, 37)}...` : cleanDescription}</p>}
        </div>

        {isEditing && Object.values(rewards).some(v => v) && (
          <>
            {rewards.hasInAppReward && <span className="redTag relative bg-yellow-400 ml-2">{intl.get('chapter_edit_inapp')}</span>}
            {rewards.hasEmailReward && <span className="redTag relative bg-yellow-400 ml-2">{intl.get('chapter_edit_email')}</span>}
            {rewards.hasRatingReward && <span className="redTag relative bg-yellow-400 ml-2">{intl.get('chapter_edit_rating')}</span>}
          </>
        )}

        {isEditing && menu}
      </div>
    </div>
  )
}

const UserAction = React.memo(({ ua }: { ua: string }) => {
  return (
    <span className="type-name">
      {intl.get(`useraction_${ua}`)}
    </span>
  )
})

const Title = ({ title, user, isCertificate, divDirection }: { title: string, user: Pick<User, 'id'|'profile'>, isCertificate: boolean, divDirection?: React.CSSProperties | {} | undefined }) => {
  const augmentedTitle =
    title
      ? augmentExplain(
          augmentGreeting(title)
          .replace(/{{firstName}}|{{name}}/g, user.profile.firstName || '')
          .replace(/{{lastName}}/g, user.profile.lastName || '')
          .replace(/{{fullName}}/g, user.profile.fullName || ''),
        )
      : ''

  return (

      <div className="action-title mr-4" style={divDirection}>
        {isCertificate && <div className="mb-4">{intl.get('session_certificate_subtitle')}</div>}
       
        {augmentedTitle === 'Optimistic update' && <div className="animate-pulse h-4 w-5/6 bg-gray-200 rounded mt-1" />}
        {augmentedTitle !== 'Optimistic update' && <span>{augmentedTitle}</span>}
      </div>

  )
}
