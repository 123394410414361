import { BASE_API_URL, LANDING_PAGE_SECRET } from "constants/General";
import { OrganizationProduct } from "graphql/schemas/organization/Organization";
import { Timestamps } from "graphql/schemas/user/UserProgress";

export type CompanyData = {
  name: string;
  _id: string;
  userCount: number;
  subsDistributionParams: any[];
  disabled: boolean|null|undefined;
  membersListEnabled: boolean|null|undefined;
  landingPageUrl: string;
  settings: {
    companyId: string;
    organizationId: string;
    landingPageEnabled: boolean;
    settings: {
      logoImage: string|undefined;
      bannerImage: string|undefined;
      primaryColor: string|undefined;
      secondaryColor: string|undefined;
      backgroundColor: string|undefined;
      description: string|undefined,
      isPublic: boolean|undefined,
    }
  }
}
export declare type Complete<T> = {
  [P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>> ? T[P] : (T[P] | undefined);
}

export declare type CourseType = 'gnowbe' | 'scorm' | 'imported_undistributable';
export declare type ChaptersUnlocked = boolean | 'unlocked' | 'daily' | 'streak';
export declare enum ChaptersUnlockedEnum {
  daily = "daily",
  streak = "streak",
  unlocked = "unlocked"
}
export declare enum CourseDifficultyEnum {
  basic = "basic",
  intermediate = "intermediate",
  advanced = "advanced",
  universal = "universal"
}
export declare type CourseDifficulty = 'basic' | 'intermediate' | 'advanced' | 'universal';
export declare enum CourseStatusEnum {
  draft = "draft",
  pipeline = "pipeline",
  ready = "ready",
  live = "live",
  archived = "archived"
}
export declare type CourseStatus = 'draft' | 'pipeline' | 'ready' | 'live' | 'archived';
export declare type Language = string;
export declare type CourseOwnerType = 'organization' | 'user';
export declare type CourseOwner = {
  type: CourseOwnerType;
  id: string;
}

export declare type CourseEmbedUpdateData = {
  chaptersCount?: number;
  actionsCount?: number;
  interactionTime?: number;
}
export declare type ScormCourseUploadStatusType = 'CREATED' | 'RUNNING' | 'COMPLETE' | 'ERROR' | 'UNKNOWN';

export declare type ScormCourseSettings = {
  uploadStatus: ScormCourseUploadStatusType;
  uploadUrl: string;
}
export declare type CourseUpdateData = {
  title?: string;
  titleEnglish?: string | null;
  tagline?: string | null;
  description?: string | null;
  imageUrl?: string | null;
  imageUrlAlt?: string | null;
  curatorName?: string | null;
  curatorTitle?: string | null;
  curatorCompany?: string | null;
  curatorBio?: string | null;
  curatorPhotoUrl?: string | null;
  curatorSignatureDisabled?: boolean | null;
  curatorChatEnabled?: boolean | null;
  /** This "seal" will show up on certificate if set */
  certSealUrl?: string | null;
  allowUnlimitedDaySkipping?: boolean | null;
  chaptersUnlocked?: ChaptersUnlocked | null;
  category?: string | null;
  categoryText?: string | null;
  skills?: string[] | null;
  difficulty?: CourseDifficulty | null;
  published?: boolean;
  status?: string | null;
  status2?: CourseStatus | null;
  tags?: string[] | null;
  cpeHours?: number | null;
  language?: Language | null;
  availableToCompanies?: string[] | null;
  availableToOrganizations?: string[] | null;
  allowPublicAssessmentsResults?: boolean | null;
  canRetakeActionAssessments?: boolean | null;
  maxActionAssessmentsRetakes?: number | null;
  showActionAssessmentsResultsPerAction?: boolean | null;
  passingGrade?: number | null;
  graduationGrade?: number | null;
  manualReviews?: boolean | null;
  hasAssessments?: boolean | null;
  workbookDisabled?: boolean | null;
  allowMediaDownload?: boolean | null;
  denyContentCopy?: boolean | null;
  contentPartnerId?: string | null;
  isTemplate?: boolean | null;
  owner?: CourseOwner | null;
  scormSettings?: ScormCourseSettings | null;
  likesCount?: number | null;
  version?: number | null;
  translatedToLanguage?: string | null;
}

export declare type CourseCreateData = CourseUpdateData & {
  title: string;
  order: number;
  published: boolean;
  parentCourseId?: string;
  masterCourseId?: string;
  type?: CourseType;
  scormSettings?: ScormCourseSettings | null;
  organizationId?: string;
  translatedToLanguage?: string | null;
}

export type Announcement = {
  _id: string;
  title: string;
  description: string;
  createdAt: number;
  updatedAt: number;
  organizationId: string;
  createdBy: string;
  updatedBy: string;
  isPublic: boolean;
  sendingPushNotification: boolean;
  sendingEmailNotification: boolean;
  companyId: string | null;
  userId: string | null;
  type: 'organization';
}


export type OrganizationData = {
  name: string;
  _id: string;
  disabled: boolean|undefined;
  featureFlags: OrganizationData;
  product: OrganizationProduct;
  isPilot: boolean|undefined;
}

export type Course = Complete<CourseCreateData&CourseEmbedUpdateData> & {
  _id: string,
  timestamps?: Timestamps,
  createdAt: number,
  updatedAt: number,
}

export type ResponseData = {
  data: {
    company: CompanyData;
    organization: OrganizationData;
    courses: Course[];
    announcements: Announcement[];
    landinPageUrl: string;
  }
}


export async function fetchCompanyData(groupId: string): Promise<ResponseData> {
  const rootUrl = `${BASE_API_URL}/api/v1/integrations/companies/landingPages`
  const rootUrlKey = LANDING_PAGE_SECRET
  const url = `${rootUrl}/${groupId}/${rootUrlKey}`
  const response = await fetch(url)

  if (!response.ok) {
    throw new Error('Failed to fetch company data')
  }

  const responseData: ResponseData = await response.json()
  return responseData
}