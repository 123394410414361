import React, { createRef, forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react'
import intl from 'react-intl-universal'
import classNames from 'classnames'
import { createRoot } from 'react-dom/client'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ApolloProvider } from '@apollo/client'
import { generateMongoId } from 'utils/random'
import { createMarkup, debounce } from 'utils/functions'
import { Transition, Dialog } from '@headlessui/react'
import { Button } from './Button'
import { client } from 'app'
import { updateMeta } from 'actions/users_actions'
import { BrowserRouter } from 'react-router-dom'

type CustomModalProps = {
  ref?: any;
  className?: string;
  title?: string | JSX.Element;
  icon?: string;
  content?: string;
  component?: any;
  withoutButtonsModal?: boolean;
  primaryText?: string;
  secondaryButton?: boolean;
  secondaryText?: string;
  cancelButton?: string;
  destructive?: boolean;
  prompt?: boolean;
  hideHeader?: boolean;
  hideFooter?: boolean;
  onlyContent?: boolean;
  defaultShowModal?: boolean;
  value?: string;
  dialogStyle?: Object;
  style?: Object;
  inside?: boolean;
  imageUrl?: string;
  tabModal?: boolean;
  tabOne?: string;
  tabTwo?: string;
  tabComponentOne?: JSX.Element;
  tabComponentTwo?: JSX.Element;
  tabTitle?: string;

  primaryAction?: (reply?: string | boolean) => void;
  secondaryAction?: () => void;
  cancelAction?: () => void;
  onHideModal?: () => void;
}

// export const replaceNodeWithReactComponent = (element: HTMLElement, reactComponent: any) => {
//   // useEffect(() => {
//   //   element.replaceWith(...Array.from(parent.childNodes))
//   // })

//   const parent = document.createElement('div')
//   const root = createRoot(parent!)
//   root.render(reactComponent)
// }

let modals: { id: string, ref: any, modal: any, cb?: any }[] = []
let modalShown = false

const addModal = (id, ref, modal, cb) => {
  modals = modals.concat({ id, ref, modal, cb })
  if (!modalShown) {
    triggerModal()
  }
}

const container = document.getElementById('modals')
const root = container && createRoot(container) || null

const triggerModal = () => {
  const nextModal = modals[0] || undefined

  if (modals.length > 0 && root) {
    root.render(<NextModalWithEffect modal={nextModal.modal} />)
  }
}

const NextModalWithEffect = ({ modal }) => {
  useEffect(() => {
    modalShown = true
    modal.cb && modal.cb()
  })

  return modal
}

export const showModal = (params: CustomModalProps, callback?: Function, close?: boolean) => {
  const ref: any = createRef()
  const id = generateMongoId()

  const cl = debounce(() => {
    const cur = modals.find(m => m.id === id)
    cur && cur.ref.current.hideModal()
    modals = modals.filter(m => m.id !== id)
    modalShown = false

    setTimeout(() => {
      triggerModal()
    }, 300)
  }, 50)

  if (!params.title && !params.content && !params.component) {
    return { close: () => cl() }
  }

  const modal = (
    <CustomModal
      ref={ref}
      defaultShowModal={close ? false : true}
      key={id}
      className={params.className || ''}
      title={params.title || 'Title'}
      icon={params.icon || undefined}
      content={params.content || 'Content'}
      component={params.component || null}
      withoutButtonsModal={params.withoutButtonsModal || undefined}
      primaryText={params.primaryText || intl.get('global_continue')}
      destructive={params.destructive || false}
      secondaryButton={params.secondaryButton !== false}
      secondaryText={params.secondaryText || intl.get('global_cancel')}
      cancelButton={params.cancelButton || ''}
      prompt={params.prompt === true}
      value={params.value || ''}
      hideHeader={params.hideHeader || false}
      hideFooter={params.hideFooter || false}
      dialogStyle={params.dialogStyle || {}}
      style={params.style || {}}
      onlyContent={params.onlyContent || false}
      imageUrl={params.imageUrl || undefined}
      primaryAction={params.primaryAction || undefined}
      secondaryAction={params.secondaryAction || undefined}
      cancelAction={params.cancelAction || undefined}
      onHideModal={() => {
        if (params.onHideModal) {
          params.onHideModal()
        }
        cl()
      }}
    />
  )

  addModal(id, ref, modal, callback)

  return {
    close: () => cl(),
  }
}

export function showQuickTip(id: string, content: string, action?: () => void) {
  return showModal({
    content,
    title: intl.get('quick_tip'),
    secondaryText: 'Don\'t show again',
    secondaryAction: () => {
      updateMeta(`/tips/${id}`, 'true', true),
        action && action()
    },
  })
}

export const CustomModal = forwardRef((props: CustomModalProps, ref) => {
  const [showModal, setShowModal] = useState(props.defaultShowModal || false)
  const [reply, setReply] = useState(undefined)

  const _replyInput: any = useRef(null)
  const _isMounted = useRef(false)
  const _refDiv = useRef(null)

  useEffect(() => {
    _isMounted.current = true
    if (_replyInput.current) {
      setTimeout(() => {
        _isMounted.current && _replyInput.current.focus()
      }, 50)
    }
    else {
      const btn = (document.querySelector('.primary-button') as HTMLInputElement)
      btn && btn.focus()
    }

    return () => {
      _isMounted.current = false
      _replyInput.current = null
    }
  }, [])

  useImperativeHandle(ref, () => ({
    hideModal,
  }))

  const hideModal = () => {
    setShowModal(false)
    props.onHideModal && props.onHideModal()
  }

  const primaryAction = () => {
    const btn = (document.querySelector('.primary-button') as HTMLInputElement)
    btn && btn.focus()
    hideModal()
    props.primaryAction && props.primaryAction(reply || false)
  }

  const secondaryAction = () => {
    (document.querySelector('.secondary-button') as HTMLInputElement).blur()
    hideModal()
    props.secondaryAction && props.secondaryAction()
  }

  const cancelAction = (e) => {
    (document.querySelector('#cancelActionButton') as HTMLInputElement).blur()
    hideModal()
    props.cancelAction && props.cancelAction()
  }

  const updateReply = (e: React.SyntheticEvent<any>) => {
    setReply(e.target['value'])
  }

  const keyDown = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      primaryAction()
    }
  }

  const setRef = (ref) => {
    if (!_replyInput.current) {
      _replyInput.current = ref
    }
  }

  const { className, value, title, icon, content, component, prompt, primaryText, destructive, secondaryButton, secondaryText, cancelButton, hideHeader, hideFooter, onlyContent, withoutButtonsModal, } = props

  const divCn = classNames({
    'relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:w-full': true,
    'px-5 pt-6 pb-5 sm:p-6': !className?.split(' ').includes('post') && !className?.split(' ').includes('join-group'),
    'sm:max-w-xl': !className?.split(' ').includes('post') && !className?.split(' ').includes('join-group') && !className?.split(' ').includes('add-action-modal'),
    'sm:max-w-3xl': className?.split(' ').includes('add-action-modal') || className?.split(' ').includes('no-content-margin'),
    'sm:max-w-4xl': className?.split(' ').includes('post') && !className?.split(' ').includes('no-content-margin'),
    [className ? className : '']: true,
  })

  const contentCn = classNames({
    'mt-4 mb-1': className !== 'post' && !className?.split(' ').includes('add-action-modal') && !className?.split(' ').includes('no-content-margin'),
  })

  return (
    <div ref={_refDiv}>
      <ApolloProvider client={client}>
        <Transition.Root show={showModal} appear={true} as={Fragment}>
          <Dialog as="div" initialFocus={_refDiv} className="fixed inset-0 overflow-y-auto custom-modal-design post" style={{ zIndex: 1060 }} open={true} onClose={hideModal}>
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div id="set-a-title-modal" className={divCn}>
                  {!hideHeader && !onlyContent && (
                    <div>
                      <div className="hidden sm:block absolute top-0 right-0 pt-5 pr-4">
                        <button
                          type="button"
                          className={`${withoutButtonsModal && 'hidden'} bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-coral`}
                          onClick={hideModal}>
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" data-test="close-modal-btn"/>
                        </button>
                      </div>

                      {(!props.imageUrl || component) && (
                        <Dialog.Title as="h3" className={`${withoutButtonsModal && 'text-center mt-8'} text-2xl font-medium font-noto-sans text-gray-900`}>
                          {icon && <i className={`icon ${icon} mr-3 -mt-0.5 font-medium text-base`} />}
                          {title || 'Title'}
                        </Dialog.Title>
                      )}
                    </div>
                  )}

                  <div className={contentCn}>
                    {onlyContent && (
                      <button
                        type="button"
                        className={`${withoutButtonsModal && 'hidden'} absolute right-0 top-0 mr-4 mt-4 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-coral rounded-md`}
                        onClick={hideModal}>
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" data-test="close-upsell-modal-btn"/>
                      </button>
                    )}

                    {component
                      ? component
                      : <div>
                        {props.imageUrl
                          ? <div className="pt-4 pb-2">
                            <img className="mx-auto" src={props.imageUrl} />
                            <div className="my-4 font-noto-sans text-2xl text-center font-bold">{title || 'Title'}</div>
                            <p dangerouslySetInnerHTML={createMarkup(content || 'Content')} className="mb-0 pb-0" />
                          </div>
                          : <p dangerouslySetInnerHTML={createMarkup(content || 'Content')} className="mb-0 pb-0" />
                        }
                      </div>
                    }

                    {prompt && (
                      <input
                        id="session-continue-input"
                        ref={setRef}
                        type="text"
                        className="w-full text-base mt-4 border-gray-300 shadow-sm rounded-md focus:ring-blue-300 focus:border-blue-300"
                        defaultValue={value}
                        onChange={updateReply}
                        onKeyDown={keyDown}
                      />
                    )}
                  </div>

                  {!hideFooter && !onlyContent && (
                    <div className="flex">
                      <div className="flex-1">
                        {cancelButton && (
                          <button id="cancelActionButton" className="mt-2" onClick={cancelAction}>
                            {cancelButton}
                          </button>
                        )}
                      </div>

                      <div className="space-x-4">
                        {secondaryButton && <Button id="session-cancel-button" className="secondary-button" text={secondaryText || intl.get('global_cancel')} onClick={secondaryAction} />}

                        <Button id="session-continue-button" className={`${withoutButtonsModal && 'hidden'} primary-button`} text={primaryText || 'Continue'} type={destructive ? 'danger' : 'primary'} onClick={primaryAction} />
                      </div>
                    </div>
                  )}
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </ApolloProvider>
    </div>
  )
})
