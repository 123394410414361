import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { GetCurrentSubscriptionRes } from 'graphql/queries/user/getUser'
import { Company } from 'graphql/schemas/company/Company'
import { Course } from 'graphql/schemas/course/Course'
import { UserProgress } from 'graphql/schemas/user/UserProgress'
import { PeerInvite } from 'components/navbar/course-list/Item'
import { showModal } from 'components/utils/CustomModal'
import { CourseItem } from './CourseItem'
import { PinCompany } from './CompanyPin'
import { isAgent } from 'graphql/schemas/user/User'
import { stringSlicer } from 'utils/functions'
import { fetchCompanyData, ResponseData } from 'graphql/queries/company/getCompanyLandingPage'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { BASE_DASHBOARD_URL } from 'constants/General'

type CompanyBoxProps = {
  isActive: boolean;
  subData: GetCurrentSubscriptionRes;
  company: Company;
  courses: (Course&UserProgress)[]|undefined;
  expanded: string|null;
  pinnable: boolean;
  archive?: boolean;
  onExpand: (org: string|null) => void;
}

export const CompanyBox = ({ isActive, subData, company, courses, expanded, pinnable, archive, onExpand }: CompanyBoxProps) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [companyLandingPageData, setCompanyData] = useState<ResponseData | null>(null)

  useEffect(() => {
    if (company.id === null) return

    const fetchData = async () => {
      try {
        const data = await fetchCompanyData(company.id)
        setCompanyData(data)
      } catch (err: any) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [company.id])

  const companyKey = `${company.organizationId}-${company.id}`
  const groupName = company.groupName || company.name
  const organizationName = company.organizationName
  const isExpanded = expanded === companyKey
  const canInvite = !archive && (company.peerInvitationMode === 'approval' || company.peerInvitationMode === 'open' || isAgent(subData.user, company.organizationId, company.id))
  const landingPageEnabled = companyLandingPageData?.data?.company?.settings?.landingPageEnabled
  const landingPageUrl = companyLandingPageData?.data?.company?.landingPageUrl
  const landingPageBannerImage = companyLandingPageData?.data?.company?.settings?.settings?.bannerImage
  const organizationId = company.organization?.id

  return (
    <div id={`${company.organizationId}-${company.id}`}>
      <div className="px-6 py-4 bg-white shadow-sm flex flex-col md:flex-row justify-between rounded-md">
        <div className="relative">
          <div className="flex">
            <img className="w-24 h-14 rounded-lg mr-4" src={landingPageEnabled ? landingPageBannerImage : undefined}/>
            <div>
              <div className="font-bold text-lg">{stringSlicer(groupName, 40)}</div>
              <div className="text-sm">{organizationName}</div>
            </div>
          </div>

          {!archive && (
            <div className="absolute right-0 top-0 md:hidden">
              <a href="#" tabIndex={-1} className={`icon icon-angle-right-light w-5 text-deepgray hover:text-coral cursor-pointer ${isExpanded ? 'up' : 'down'} text-3xl`} onClick={(e) => {
                e.preventDefault()
                onExpand(isExpanded ? null : companyKey)
              }} />

              {pinnable && (
                <div>
                  <PinCompany
                    userId={subData.user.id}
                    companyId={company.id}
                    pinnedCompanyIds={subData.user.pinnedCompanyIds}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex flex-col md:flex-row md:items-center mt-4 md:mt-0 space-y-4 md:space-y-0 md:space-x-10">
          <div className="flex flex-col md:flex-row gap-4 items-start md:items-center">
            <div className="flex items-center">
              <span className="inline-block mr-1.5">
                <img src="/images/icon-book.svg" className="w-3 h-4 md:w-auto" />
              </span>

              <div className="flex items-center">
                <div className="font-bold">
                  <button className="text-deepgray hover:text-coral"  onClick={() => {
                    onExpand(isExpanded ? null : companyKey)
                  }}>
                    Programs
                  </button>
                </div>

                <div className="text-sm hidden md:block">
                  &nbsp;
                </div>
              </div>
            </div>
            <div className="flex items-center">
              {landingPageEnabled ? (
                <span className="inline-block mr-1.5">
                  <img src="/images/view-eye-icon.svg" className="w-4 h-5 md:w-auto" />
                </span>
              ) : (
                <span className="inline-block mr-1.5">
                  <img src="/images/view-eye-gray-icon.svg" className="w-4 h-5 md:w-auto" />
                </span>
              )}

              <div className="flex items-center">
                <div className="font-bold">
                  <a
                    className={landingPageEnabled ? 'text-deepgray hover:text-coral' : 'text-gray-400 cursor-not-allowed pointer-events-none'}
                    href={landingPageUrl}
                    data-test="landing-page-btn"
                    target='_blank' rel="noreferrer">
                    Landing Page
                  </a>
                </div>

                <div className="text-sm hidden md:block">
                  &nbsp;
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <span className={`inline-block mr-1.5 ${canInvite ? 'text-lake' : 'text-gray-400'}`}>
                <i className="icon icon-users-solid w-8 md:w-auto" />
              </span>

              <div className="flex items-center">
                <div className="font-bold">
                  <button className={canInvite ? 'text-deepgray hover:text-coral' : 'text-gray-400 cursor-not-allowed'} onClick={() => {
                    if (!canInvite) return

                    const modal = showModal({
                      title: intl.get('home_group_invite'),
                      component: (
                        <PeerInvite
                          companyId={company.id}
                          isAgent={isAgent}
                          onClose={() => modal.close()}
                        />
                      ),
                      secondaryButton: false,
                      primaryText: intl.get('global_close'),
                    })
                  }}>
                    {intl.get('share_text')}
                  </button>
                </div>

                <div className="text-sm hidden md:block">
                  &nbsp;
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <span className="inline-block mr-1.5">
                {canInvite ? (
                    <img src="/images/icon-settings-new.svg" className="w-4 h-5 md:w-auto" />
                  )
                : (
                  <Cog6ToothIcon className="w-4 h-5 md:w-auto" />
                )}
              </span>

              <div className="flex items-center">
                <div className="font-bold">
                  <button disabled={!canInvite} className={canInvite ? 'text-deepgray hover:text-coral' : 'text-gray-400 cursor-not-allowed'} onClick={() => {
                    if (!canInvite) return
                    window.open(`${BASE_DASHBOARD_URL}/organization/${organizationId}/company/${company.id}?scrollTo=settings-company-landing-pages#settings`, '_blank')
                  }}>
                    Settings
                  </button>
                </div>

                <div className="text-sm hidden md:block">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>

          {!archive && (
            <>
              {pinnable && (
                <div className="hidden md:block">
                  <PinCompany
                    userId={subData.user.id}
                    companyId={company.id}
                    pinnedCompanyIds={subData.user.pinnedCompanyIds}
                  />
                </div>
              )}

              <div className="hidden md:block">
                <button
                  className={`icon icon-angle-right-light text-deepgray hover:text-coral cursor-pointer ${isExpanded ? 'up' : 'down'} text-3xl`}
                  onClick={() => onExpand(isExpanded ? null : companyKey)}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {courses && isExpanded && (
        <div className="mt-4 grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-6 4xl:grid-cols-8 gap-4">
          {courses.map((course) => {
            if (!course) return null
            const subKey = `${company.id}-${course.id}`
            const isLiked = subData.user.favoritedSubscriptions.includes(subKey)
            const progress = (course as any).progress
            return (
              <CourseItem
                key={subKey}
                userId={subData.user.id}
                company={company}
                course={course}
                liked={isLiked}
                isArchived={!!archive}
                isActive={isActive}
                isAgent={isAgent(subData.user, company.organizationId, company.id)}
                isEditor={subData.user.editorCourses.includes(course.id)}
                paddingRight={false}
                dontShowGroups={true}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
