import React from 'react'
import intl from 'react-intl-universal'
import { useQuery, ApolloProvider, gql } from '@apollo/client'
import { resetUserAssessments } from 'actions/courses_actions'
import { showModal } from 'components/utils/CustomModal'
import { getUserAndActiveCourseQuery, GetUserAndActiveCourseResponse } from 'graphql/queries/user/getUser'
import { client } from 'app'
import { subsDistributionParamsFragment } from 'graphql/fragments/Company'
import { getChaptersForCourseQuery, GetChaptersForCourseRes, GetChaptersForCourseVars } from 'graphql/queries/chapter/getChaptersForCourse'
import { getStudyForSubscriptionQuery, GQLStudyForSubscription2Response, GQLStudyForSubscriptionVariables } from 'graphql/queries/user/getStudyForSubscription'
import { getProgressQuery, GetProgressRes } from 'graphql/queries/user/updateProgress'
import { camelCase } from 'utils/functions'
import { subsDistributionParamsFactory } from 'graphql/schemas/company/Company'
import { getUserAssessmentsResultsQuery } from 'graphql/queries/user/getUserAssessmentsResults'
import { hideSlider, showSlider } from 'components/utils/Slider'
import { Course, isCourseExpired } from 'graphql/schemas/course/Course'
import { history } from 'utils/history'

type SliderProps = {
  minScore?: number;

  companyId: string;
  courseId: string;
  userId: string;

  nextAction?: () => void;
}

const Success = (props: SliderProps) => {
  // const [res, setRes] = useState('accepted')

  const nextAction = (e) => {
    e.preventDefault()
    const doc = document.getElementById('sliderSuccess')
    if (doc) {
      doc.classList.add('close')
    }
    if (props.nextAction) {
      props.nextAction()
    }
  }

  // getProctoringResults(props.companyId, props.courseId, props.userId).then(setRes)

  let title = intl.get('activity_assesment_passed_header_text')
  let subtitle: string|null = intl.getHTML('activity_assesment_passed_subheader_text', { 0: '{0}' })

  // if (res === 'no_results') {
  //   title = intl.get('proctoring_awaiting_results')
  //   subtitle = null
  // }

  // if (res === 'rejected') {
  //   title = intl.get('proctoring_rejected')
  //   subtitle = intl.getHTML('activity_assesment_passed_failed_proctoring_subheader_text', { 0: '{0}', 1: props.minScore })
  // }

  return (
    <div className="text-center" style={{ width: 450 }}>
      <h1 style={{ marginTop: -40 }}>
        {title}
      </h1>

      <div className="text-2xl font-medium leading-9 text-white font-noto-sans" id="h2-assessment">
        {subtitle}
      </div>

      {/* {res !== 'no_results' && ( */}
        <ApolloProvider client={client}>
          <div className="mt-12">
            <ResetAssessmentsButton
              type="success"
              results={false}
              companyId={props.companyId}
              courseId={props.courseId}
              userId={props.userId}
            />
          </div>
        </ApolloProvider>
      {/* )} */}

      <div className="mt-12">
        <a href="#" onClick={nextAction}>
          <img src="/images/icon-next-success.png" className="inline-block" />
        </a>
      </div>
    </div>
  )
}

const Fail = (props: SliderProps) => {
  const nextAction = (e) => {
    e.preventDefault()
    const doc = document.getElementById('sliderFail')
    if (doc) {
      doc.classList.add('close')
    }
    if (props.nextAction) {
      props.nextAction()
    }
  }

  return (
    <div className="text-center" style={{ width: 450 }}>
      <h1 style={{ marginTop: -40, color: 'white' }}>
        {intl.get('activity_assesment_failed_header')}
      </h1>

      <div className="text-2xl font-medium leading-9 text-white font-noto-sans" id="h2-assessment">
        {intl.get('activity_assesment_failed_subheader', { 0: '{0}', 1: props.minScore })}
      </div>

      <ApolloProvider client={client}>
        <div className="mt-12">
          <ResetAssessmentsButton
            type="fail"
            results={false}
            companyId={props.companyId}
            courseId={props.courseId}
            userId={props.userId}
          />
        </div>
      </ApolloProvider>

      <div className="mt-12">
        <a href="#" onClick={nextAction}>
          <img src="/images/icon-next-fail.png" className="inline-block" />
        </a>
      </div>
    </div>
  )
}

const Progress = () => {
  return (
    <div className="text-center" style={{ width: 450 }}>
      <h1 style={{ marginTop: -40 }}>{intl.get('activity_evaluating_title')}</h1>
      <h2 className="leading-10 text-white text-3xl">{intl.get('activity_evaluation_subtitle')}</h2>
      <h2 className="mt-12">{intl.get('activity_evaluate_text')}</h2>
    </div>
  )
}


// const getProctoringResults = async (companyId, courseId, userId) => {
//   const data: any = await client.query({
//     query: getEngagementsForSubscriptionQuery,
//     variables: {
//       companyId: companyId,
//       courseId: courseId,
//       userId: userId,
//     },
//   })

//   let status: 'no_results'|'accepted'|'rejected' = 'accepted'

//   if (!data) return status

//   for (const eng of data.data.engagement) {
//     if (status !== 'accepted') continue
//     if (eng.assessmentsHasProctoring && eng.assessmentsProctorResults?.status === 'no_results') {
//       status = 'no_results'
//     }
//     if (eng.assessmentsHasProctoring && eng.assessmentsProctorResults?.status === 'rejected') {
//       status = 'rejected'
//     }
//   }

//   return status
// }

const getSubsDistributionParamsQuery = gql`
  ${subsDistributionParamsFragment}

  query GetSubsDistributionParams ($companyId: String!, $courseId: String!, $userId: String!) {
    subscription: getUserSubscription (companyId: $companyId, courseId: $courseId, userId: $userId) {
      id
      company {
        id
        subsDistributionParams {
          ...SubsDistributionParamsFragment
        }
      }
    }
  }
`

const AssessmentResults = ({ companyId, courseId, userId, passingGrade, isEditor, nextAction }) => {
  const { data: chapterData, loading: chapterLoading, error: chapterError } = useQuery<GetChaptersForCourseRes, GetChaptersForCourseVars>(getChaptersForCourseQuery, { variables: {
    companyId,
    courseId,
    returnAllAssessments: !!isEditor,
  }})

  const { data: studyData, loading: studyLoading, error: studyError } = useQuery<GQLStudyForSubscription2Response, GQLStudyForSubscriptionVariables>(getStudyForSubscriptionQuery, { variables: {
    companyId,
    courseId,
    userId,
  }})

  const { data: subData, loading: subLoading, error: subError } = useQuery(getSubsDistributionParamsQuery, { variables: {
    companyId,
    courseId,
    userId,
  }})

  const { data: progressData } = useQuery<GetProgressRes>(getProgressQuery, { variables: {
    companyId,
    courseId,
    userId,
  }})

  if (chapterLoading || studyLoading || subLoading) {
    return <div>Loading...</div>
  }

  if (chapterError || studyError || subError) {
    return <div>Error</div>
  }

  if (!chapterData || !studyData || !subData || !progressData) {
    return <div>No data</div>
  }

  const getActions = (chapterId: string) => {
    const chapter = chapterData.chapters.find(c => c.id === chapterId)
    const studies = studyData.study
    const actions = chapter && chapter.actions.filter(a => a.contentType === 'assessment').sort((a, b) => a.order - b.order).map((a) => {
      const studyForAction = studies.find(s => s.actionId === a.id)
      return {
        id: a.id,
        title: a.title,
        order: a.order,
        correct: studyForAction && studyForAction.isAssessmentCorrect || false,
      }
    })
    return actions
  }

  const chapters = chapterData.chapters.slice().sort((a, b) => a.order - b.order).map((c) => {
    return {
      id: c.id,
      title: c.title,
      order: c.order,
      actions: getActions(c.id),
    }
  })

  const open = (chapterId, actionId) => {
    return history.navigate && history.navigate(`/learn/${chapterId}/${actionId}`)
  }

  const distributionParams = subData.subscription.company?.subsDistributionParams.find(c => c.courseId === courseId) || subsDistributionParamsFactory()
  const hideAssessmentsAfterCompleted = distributionParams.hideAssessmentsAfterCompleted
  const isExpired = isCourseExpired({
    absoluteDeadline: distributionParams.absoluteDeadline,
    startedAt: subData.subscription.progress?.startedAt || 0,
    relativeDeadline: distributionParams.relativeDeadline,
  })

  // remember to show correct message here when proctoring is enabled

  const assessmentsGrade = progressData.getUserSubscription?.progress.assessmentsGrade || 0

  return (
    <>
      <div className="absolute inset-0 mt-64 bg-verylightbrown z-0" />

      <div className="absolute right-12 top-5 z-10">
        <img src="/images/times-light.svg" className="w-4 cursor-pointer" />
      </div>

      <div className="w-5/6 sm:w-1/3 z-10" data-test="assessment-result">
        <div className="text-center bg-white py-4 rounded shadow-sm mb-6">
          <h2 className={`my-3 sm:my-5 text-xl sm:text-2xl ${assessmentsGrade >= passingGrade ? 'text-actions-multiple_choice' : 'text-coral'}`}>
            {assessmentsGrade >= passingGrade ? intl.get('assessment_right_drawer_passed_text') : intl.get('you_didn_t_pass_the_assessment')}
          </h2>

          <div className="mt-4 leading-6 font-bold text-deepgray">
            {intl.get('assessments_results_your_score', { 0: assessmentsGrade })}<br/>
            {intl.get('assessments_results_score_needed', { 0: passingGrade })}
          </div>
        </div>

        <ul style={{ maxHeight: 'calc(100vh - 420px)' }} className="overflow-y-auto">
          {chapters.map((c) => {
            return (
              <li key={c.id} className="mb-6 text-deepgray">
                <strong className="uppercase">{intl.get('assessments_results_header_placeholder', { 0: c.order, 1: c.title })}</strong>

                <ul className="mt-2 shadow-sm rounded-sm">
                  {(c.actions || []).map((a) => {
                    return (
                      <li key={a.id} className="bg-white py-2 px-4 flex items-center" style={{ marginBottom: 1 }}>
                        <div className={`${a.correct ? 'icon-check-light text-green-600' : 'icon-times-light text-red-600'} mr-4 text-center`} />
                        <div className="font-bold flex-1">{intl.get('assessments_results_item_placeholder', { 0: a.order, 1: a.title })}</div>
                        {!hideAssessmentsAfterCompleted && !isExpired && <a href="#" onClick={() => open(c.id, a.id)} className="text-sm text-lake">{intl.get('assessments_results_view')}</a>}
                      </li>
                    )
                  })}
                </ul>
              </li>
            )
          })}
        </ul>

        <div className="text-center mt-6">
          {!isExpired && (
            <ResetAssessmentsButton
              type={assessmentsGrade >= passingGrade ? 'success' : 'fail'}
              companyId={companyId}
              courseId={courseId}
              userId={userId}
              scores={true}
            />
          )}

          <input type="button" className="btn btn-default px-4 py-2" value={camelCase(intl.get('close'))} data-test="result-close-btn"/>
        </div>

        {!isExpired && nextAction && (
          <div className="mt-12 text-center">
            <a href="#" onClick={nextAction}>
              <img src="/images/icon-next-green.svg" className="inline-block" />
            </a>
          </div>
        )}
      </div>
    </>
  )
}

export const showAssessmentResults = ({ companyId, course, userId, isEditor, skipProgress }: { companyId: string, course: Course, userId: string, isEditor: boolean, skipProgress?: boolean }) => {
  let _mounted = false
  let counter = 0
  const t = setInterval(async () => {
    counter += 1
    const data: any = await client.query({
      query: getUserAssessmentsResultsQuery,
      variables: {
        userId,
        companyId,
        courseId: course.id,
      },
      fetchPolicy: skipProgress ? 'cache-first' : 'network-only',
    })
    const userSub = data.data.getUserSubscription
    if (!userSub) return

    const progress = userSub.progress
    if (progress && progress.assessmentsCompleted) {
      // const proctoringResults = await getProctoringResults(companyId, course.id, userId)

      if (!skipProgress) {
        showSlider('progress-slider', 'progress', <Progress />, () => hideSlider('progress-slider'))
      }

      setTimeout(() => {
        if (!skipProgress) {
          hideSlider('progress-slider')
        }

        setTimeout(() => {
          if (_mounted) return

          _mounted = true
          const sliderName = course.showActionAssessmentsResultsPerAction
            ? 'answers-slider'
            : progress.assessmentsGrade < course.passingGrade // || proctoringResults === 'rejected'
              ? 'fail-slider'
              : 'success-slider'
          const sliderClass = progress.assessmentsGrade < course.passingGrade ? 'fail' : 'success' //  || proctoringResults === 'rejected'
          const sliderComponent = course.showActionAssessmentsResultsPerAction
            ? <ApolloProvider client={client}>
                <AssessmentResults
                  companyId={companyId}
                  courseId={course.id}
                  userId={userId}
                  isEditor={isEditor}
                  passingGrade={course.passingGrade}
                  nextAction={undefined}
                />
              </ApolloProvider>
            : progress.assessmentsGrade < course.passingGrade
              ? <Fail
                  companyId={companyId}
                  courseId={course.id}
                  userId={userId}
                  minScore={course.passingGrade}
                />
              : <ApolloProvider client={client}>
                  <Success
                    companyId={companyId}
                    courseId={course.id}
                    userId={userId}
                  />
                </ApolloProvider>
          showSlider(sliderName, sliderClass, sliderComponent, () => hideSlider(sliderName), () => {
            setTimeout(() => {
              const gradeSpan = document.getElementById('h2-assessment')
              if (gradeSpan) {
                gradeSpan.innerHTML = gradeSpan.innerHTML.replace('{0}', (progress.assessmentsGrade).toString())
              }
            }, 0)
          })
          return clearInterval(t)
        }, !skipProgress ? 500 : 0)
      }, !skipProgress ? 2000 : 0)
    }

    if (!skipProgress && counter >= 3) {
      hideSlider('progress-slider')
      showModal({
        title: intl.get('error_dialog_title'),
        content: 'We weren\'t able to get the score. Try again in a little while.',
        primaryText: intl.get('ok'),
        primaryAction: () => history.navigate && history.navigate('/learn'),
      })
      return clearInterval(t)
    }

    if (skipProgress) clearInterval(t)
  }, !skipProgress ? 2000 : 0)
}

export const ResetAssessmentsButton = ({ companyId, courseId, userId, type, scores, results }: { companyId: string, courseId: string, userId: string, type: 'success'|'fail', scores?: boolean, results?: boolean }) => {
  const { data, loading, error } = useQuery<GetUserAndActiveCourseResponse>(getUserAndActiveCourseQuery, { variables: {
    companyId,
    courseId,
    userId,
  }})

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error</div>
  if (!data) return <div>Missing data</div>

  if (!data.course.canRetakeActionAssessments || ((data.subscription?.progress?.assessmentsRetakeNum || 0) > data.course.maxActionAssessmentsRetakes)) return null

  const reset = () => {
    showModal({
      title: intl.get('dialog_are_you_sure'),
      component: intl.getHTML('assessments_reset_confirmation', { 0: data.subscription?.progress?.assessmentsRetakeNum || 0, 1: data.course.maxActionAssessmentsRetakes }),
      primaryText: intl.get('reset_score'),
      primaryAction: () => {
        resetUserAssessments({ companyId, courseId }, true)
      },
    })
  }

  if (results === false) {
    return (
      <input
        type="button"
        className="btn btn-default font-medium uppercase px-10 py-2 mr-2"
        value={intl.get('reset_score')}
        onClick={reset}
      />
    )
  }

  if (type === 'success') {
    return (
      <input
        type="button"
        className="btn btn-primary font-medium uppercase px-10 py-2 mr-2"
        value={intl.get('reset_score')}
        onClick={reset}
      />
    )
  }

  if (scores) {
    return (
      <input
        type="button"
        className="btn btn-primary font-medium mr-2 px-10 py-2 uppercase"
        value={intl.get('reset_score')}
        onClick={reset}
      />
    )
  }

  return (
    <input
      type="button"
      className="btn btn-primary px-4 py-2 mr-2 bg-white text-deepgray border-white hover:bg-lake hover:border-lake hover:text-white"
      value={intl.get('reset_score')}
      onClick={reset}
    />
  )
}