import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useNavigate } from "react-router";
import intl from 'react-intl-universal'
import { updateAppCache } from 'app';

const BackButton = ({ tab, isEditing, mobile }: { tab: string, isEditing?: boolean, mobile?: boolean }) => {
  const navigate = useNavigate()
  return ( 
    <div className="my-auto px-3 w-auto">
      <button className="flex gap-2 text-actions-multiple_choice font-bold" onClick={() => {
        const url = new URL(decodeURIComponent(window.location.href))
        const referrer = url.searchParams.get('referrer')
        if (referrer && referrer.includes('gnowbe.com')) {
          return window.location.href = referrer
        }
        if (isEditing) {
          updateAppCache('isEditing', false)
        }
        navigate(-1)
      }}>
        <ArrowLeftIcon className="w-6 h-6" data-test="back-btn"/> <div className={`${tab === 'search' ? 'hidden xl:block xl:w-full' : 'hidden xl:block xl:w-full'}`}>{intl.get('global_back')}</div>
      </button>
    </div>
   );
}
 
export default BackButton;