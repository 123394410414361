declare let COMMIT_HASH
let baseUrl
let baseApiUrl = 'https://api.gnowbe.com'
let baseBeGnowbeUrl = 'https://be.gnowbe.com'
let baseDashboardUrl = 'https://dashboard.gnowbe.com'
let goodieUrl = 'https://be.gnowbe.com'
let certificateUrl = 'https://be.gnowbe.com/previews/certificate'
let graphql = `https://graph.gnowbe.com/graphql?p=web_${COMMIT_HASH}`
let graphqls = `wss://graph.gnowbe.com/graphqls?p=web_${COMMIT_HASH}`
let gnowbeSource = `gnowbe-web ${COMMIT_HASH}`
let appStoreUrl = 'https://gnowbe.app.link'
let gnowbeBotUrl = 'https://api.gnowbe.com/api/v1/integrations/bot/'

const landingPageSecret = '6f4e9b3d-12ac-84b7-9e5f-bf29ac7e3d6a'

const absoluteUrl = window.location.toString()

if (process.env.BUILD === 'production') {
  baseUrl = /localhost:|\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b:/i.test(absoluteUrl)
    ? 'http://localhost:8080'
    : 'https://web.gnowbe.com'
}
if (process.env.BUILD === 'staging') {
  baseUrl = /localhost:|\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b:/i.test(absoluteUrl)
    ? 'http://localhost:8080'
    : 'https://web.staging.sandbox.gnowbe.com'
  baseApiUrl = 'https://api.staging.sandbox.gnowbe.com'
  baseBeGnowbeUrl = 'https://be.staging.sandbox.gnowbe.com'
  baseDashboardUrl = 'https://dashboard.staging.sandbox.gnowbe.com'
  goodieUrl = 'https://be.staging.sandbox.gnowbe.com'
  certificateUrl = 'https://be.staging.sandbox.gnowbe.com/previews/certificate'
  graphql = `https://graph.staging.sandbox.gnowbe.com/graphql?p=web_${COMMIT_HASH}`
  graphqls = `wss://graph.staging.sandbox.gnowbe.com/graphqls?p=web_${COMMIT_HASH}`
  gnowbeBotUrl = `https://api.staging.sandbox.gnowbe.com/api/v1/integrations/bot/`
}
if (process.env.BUILD === 'development') {
  baseUrl = 'http://localhost:8080'
  baseApiUrl = 'http://localhost:3005'
  baseBeGnowbeUrl = 'http://localhost:3005'
  baseDashboardUrl = 'http://localhost:3002'
  goodieUrl = 'http://localhost:3005'
  certificateUrl = 'http://localhost:3005/previews/certificate'
  graphql = `http://localhost:3005/graphql?p=web_${COMMIT_HASH}`
  graphqls = `ws://localhost:3005/graphqls?p=web_${COMMIT_HASH}`
}
if (process.env.BUILD === 'JTLEARNING') {
  baseUrl = 'https://jamestownlearning.com'
  gnowbeSource = `${gnowbeSource}-JTLEARNING`
  appStoreUrl = 'https://jtlearning.app.link'
  graphql = `${graphql}-JTLEARNING`
  graphqls = `${graphqls}-JTLEARNING`
}
if (process.env.BUILD === 'DAVIDCCOOK') {
  baseUrl = 'https://web.dcc-go.com'
  gnowbeSource = `${gnowbeSource}-DAVIDCCOOK`
  appStoreUrl = 'https://davidccooklearning.app.link'
  graphql = `${graphql}-DAVIDCCOOK`
  graphqls = `${graphqls}-DAVIDCCOOK`
}
if (process.env.BUILD === 'FINEXIS') {
  baseUrl = 'https://impactu.schoolofimpact.com'
  gnowbeSource = `${gnowbeSource}-FINEXIS`
  appStoreUrl = 'https://impactu.app.link'
  graphql = `${graphql}-FINEXIS`
  graphqls = `${graphqls}-FINEXIS`
}
if (process.env.BUILD === 'NTULEARN') {
  baseUrl = 'https://ntulearnplus.ntu.edu.sg'
  gnowbeSource = `${gnowbeSource}-NTULEARN`
  appStoreUrl = 'https://ntulearn.app.link'
  graphql = `${graphql}-NTULEARN`
  graphqls = `${graphqls}-NTULEARN`
}
if (process.env.BUILD === 'GIC') {
  baseUrl = 'https://eLearn.gic.com.sg'
  gnowbeSource = `${gnowbeSource}-GIC`
  appStoreUrl = 'https://elearnatgic.app.link'
  graphql = `${graphql}-GIC`
  graphqls = `${graphqls}-GIC`
}

export const BASE_URL = baseUrl
export const BASE_API_URL = baseApiUrl
export const BASE_BE_GNOWBE_URL = baseBeGnowbeUrl
export const BASE_DASHBOARD_URL = baseDashboardUrl
export const DASHBOARD_DEEPLINK = `${baseDashboardUrl}/d`
export const GOODIE_URL = goodieUrl
export const CERTIFICATE_URL = certificateUrl
export const GRAPHQL = graphql
export const GRAPHQLS = graphqls
export const GNOWBE_SOURCE = gnowbeSource
export const APP_STORE_URL = appStoreUrl
export const GNOWBE_BOT_URL = gnowbeBotUrl
export const APP_TITLE = {
  development: 'Gnowbe Web Development',
  staging: 'Gnowbe Web Staging',
  production: 'Gnowbe Web',
  JTLEARNING: 'Jamestown Learning',
  DAVIDCCOOK: 'DCC GO',
  FINEXIS: 'SOI ImpactU',
  NTULEARN: 'NTULearn+',
  GIC: 'eLearn@GIC',
}
export const MOBILE_APP_TITLE = {
  development: 'Gnowbe',
  staging: 'Gnowbe',
  production: 'Gnowbe',
  JTLEARNING: 'Jamestown Learning',
  DAVIDCCOOK: 'DCC GO',
  FINEXIS: 'SOI ImpactU',
  NTULEARN: 'NTULearn+',
  GIC: 'eLearn@GIC',
}
export const HIDE_HELP = ['GIC', 'FINEXIS']

export const DEFAULT_AVATAR_URL = 'https://d243zh5p2vkyyi.cloudfront.net/users/assets/default-avatar.png'

export const COURSE_CATEGORIES = [
  { id: 'LF', title: 'Life Skills', order: 1, description: 'Programs that focus on adaptive and positive behaviour that enable individuals to deal effectively with the demands and challenges of everyday life.' },
  { id: 'LS', title: 'Leadership & Management', order: 2, description: 'Programs that explore leadership approaches and management practices.' },
  { id: 'BS', title: 'Business', order: 3, description: 'Programs that focus on business functions (finance, entrepreneurship, communications, operations, project managementhome business, HR,).', color: '#D4AFB9' },
  { id: 'SM', title: 'Sales & Marketing', order: 4, description: 'Programs that focus on the forms of sales and marketing.', color: '#7EC4CF' },
  { id: 'DS', title: 'Design', order: 5, description: 'Programs focused on the different types of design such as graphic design, web design, interior design etc...' },
  { id: 'PS', title: 'Professional Skills', order: 6, description: 'Programs that focus on career competencies and knowledge expertise in specific fields.' },
  { id: 'GK', title: 'Events & Workshops', order: 7, description: 'Programs that focus on hobbies, languages, general interests, and other recreational pursuits.', color: '#9CADCE' },
  { id: 'IN', title: 'Innovation & Technology', order: 8, description: 'Programs that focus on new ways of thinking in innovation and technology.' },
  { id: 'ES', title: 'Engineering & Applied Sciences', order: 9, description: 'Programs that are focused on applying scientific and engineering principles to building innovative applications.' },
  { id: 'CE', title: 'Culture & Engagement', order: 10, description: 'Programs that focus on fostering and sharing social culture (e.g., country, company, subgroup, etc.) and keeping users engaged.', color: '#D1CFE2' },
  { id: 'CS', title: 'Custom', order: 11, description: '' },
  { id: 'notSet', title: 'Uncategorized', order: 12, description: '' },
]

export const courseDifficulties = [
  { id: 'basic', title: 'Basic', order: 1 },
  { id: 'intermediate', title: 'Intermediate', order: 2 },
  { id: 'advanced', title: 'Advanced', order: 3 },
  { id: 'universal', title: 'Universal', order: 4 },
]

export const languageDefault = 'en'
export const languages = [
  { id: 'en', title: 'English' },
  { id: 'bg', title: 'Bulgarian' },
  { id: 'id', title: 'Bahasa Indonesia' },
  { id: 'da', title: 'Dansk' },
  { id: 'de', title: 'Deutsch' },
  { id: 'es', title: 'Español' },
  { id: 'fr', title: 'Français' },
  { id: 'hu', title: 'Magyar' },
  { id: 'it', title: 'Italiano' },
  { id: 'nl', title: 'Nederlands' },
  { id: 'no', title: 'Norsk' },
  { id: 'pl', title: 'Polski' },
  { id: 'pt', title: 'Portuguese' },
  { id: 'sl', title: 'Slovenščina' },
  { id: 'sv', title: 'Svenska' },
  { id: 'ru', title: 'русский' },
  { id: 'my', title: 'မြန်မာစာ' },
  { id: 'ko', title: '한국어' },
  { id: 'ja', title: '日本語' },
  { id: 'zh', title: '简体中文' },
  { id: 'th', title: 'Thai' },
  { id: 'vi', title: 'Vietnamese' },
  { id: 'ms', title: 'Bahasa Melayu' },
  { id: 'tw', title: 'Traditional Chinese (繁體中文)' },
  // { id: 'ar', title: 'Arabic' },
]
export const languagesSet = new Set(languages.map(l => l.id))

export const pushNotifications = [
  { title: 'Private Messages', id: 'PUSH_PRIVATE_MSGS', translation: 'settings_notification_private_msgs' },
  { title: 'Curator Messages', id: 'PUSH_CURATOR_MSGS', translation: 'settings_notification_curator_msgs' },
  { title: 'Comments/Likes', id: 'PUSH_COMMENTS_LIKES', translation: 'settings_notification_comments_likes' },
  { title: 'Mentions in Messages', id: 'PUSH_MENTIONS', translation: 'settings_notification_mentions' },
  { title: 'Daily Program Messaging', id: 'PUSH_DAILY_COURSE_MSGS', translation: 'settings_notification_daily_msgs' },
  { title: 'Inactivity Messaging', id: 'PUSH_INACTIVITY', translation: 'settings_notification_inactivity' },
  { title: 'Group Agent Communication', id: 'PUSH_GROUP_AGENT_MSGS', translation: 'settings_notification_agent_msgs' },
]

export const emailNotifications = [
  { title: 'Daily Program Emails', id: 'EMAIL_DAILY_COURSE_MSGS', description: 'Daily Program Emails are emails sent by program curators to notify you of the topic of the day.\nYou may receive one daily email per program at 7 am local time until the program ends.\nSometimes a little reminder helps us complete. ⏲', translationKey: 'settings_notifications_daily_emails', translationValue: 'settings_notifications_daily_emails_description' },
  { title: 'Program Completion Emails', id: 'EMAIL_COURSE_COMPLETION_MSGS', description: 'Program Completion Emails are emails that curators may send you after you complete certain actions. Sometimes there are goodies within these emails. 🍒', translationKey: 'settings_notifications_completion_emails', translationValue: 'settings_notifications_completion_emails_description' },
  { title: 'Weekly Journey Emails', id: 'EMAIL_WEEKLY_JOURNEY', description: 'This is a weekly summary of your Gnowbe activity.\nYou can track your progress and ranking here to ensure your success!', translationKey: 'settings_notifications_journey_emails', translationValue: 'settings_notifications_journey_emails_description' },
  { title: 'Activity Notifications', id: 'EMAIL_ACTIVITY_NOTIFICATIONS', description: 'This email notifies you when you have any unseen private messages in Gnowbe.', translationKey: 'settings_notifications_activity_emails', translationValue: 'settings_notifications_activity_emails_description' },
  { title: 'Group Agent Communication Emails', id: 'EMAIL_GROUP_AGENT_MSGS', description: 'These messages come from the agents/managers within your group and can be sent anytime via email or push notification.\nThis is the group\'s way of communicating to its members.', translationKey: 'settings_notifications_group_agent_emails', translationValue: 'settings_notifications_group_agent_emails_description' },
  { title: 'Invitation Emails', id: 'EMAIL_INVITATIONS', description: 'This email notifies you when you are invited to different roles and when new content is available to you.', translationKey: 'settings_notifications_invitation_emails', translationValue: 'settings_notifications_group_agent_emails_description' },
]

export const ALL_ACTIONS = [
  'multiple_choice',
  'qa',
  'photo',
  'image',
  'link',
  'text',
  'audio',
  'external',
  'quote',
  'video',
  'certificate',
  'auth_web_link',
  'assessment',
  'upload',
  'word_puzzle',
]

export const NO_INTERACTION_ACTIONS = [
  'image',
  'link',
  'text',
  'audio',
  'external',
  'quote',
  'video',
  'certificate',
  'auth_web_link',
]

export const NO_NEED_TO_BE_VIEWED_ACTIONS = [
  'quote',
  'certificate',
]

export const CAN_HAVE_REMINDER = [
  'qa',
  'photo',
  'upload',
]

export const MUST_HAVE_ANSWER = [
  'qa',
  'photo',
  'multiple_choice',
  'assessment',
  'upload',
  'word_puzzle',
]

export const CAN_BE_SHARED = [
  'qa',
  'photo',
  'word_puzzle',
]

export const CAN_HAVE_IMAGE = [
  'qa',
  'multiple_choice',
  'text',
  'image',
  'assessment',
  'word_puzzle',
]

export const NO_DESCRIPTION = [
  'certificate',
  'quote',
  'link',
  'external',
  'word_puzzle',
]

export const LOCALSTORAGE_VARS = [
  'currentChapters',
  'dismissAppWidget',
  'currentCompanyIdCourseId',
  'groupLoaded',
  'videoQuality',
  'userId',
  'course',
  'masked-ip',
  'joyridesDismissed',
  'chapter',
  'currentChapters',
  'showCuratorChat',
  'fullName',
  'email',
  'companyId',
  'leaveLanguage',
  'copiedChapter',
  'copiedAction',
  'gnowbe_menu_expanded',
  'gnowbe_submenu_expanded',
  'gnowbe_seen_modals',
  'gnowbe_word_puzzle_guide_seen',
]

export const LANDING_PAGE_SECRET = landingPageSecret
